<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-row align="center" justify="center">
        <v-col cols="12">
            <base-material-card
                icon="mdi-account-plus"
                title="Person List"
                class="px-1 py-4"
            >
                <v-data-table
                :headers="Headers"
                :items="user"
                disable-pagination
                :hide-default-footer="true"
                >
                <template v-slot:top>
                  <v-toolbar
                    flat
                  >
                    <v-spacer></v-spacer>
                    <v-dialog
                        v-model="dialog"
                        max-width="50%"
                      >
                      <template v-slot:activator="{ on, attrs }">

                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New User
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>Select the Role for the New Person</v-card-title>
                        <v-card-actions>
                        <v-card-text>
                          <v-container>
                            <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>
                                <v-col
                                     cols="12"
                                     md="4">

                                  <v-select
                                      v-model="selected_user.Role"
                                     label="Role"
                                     :items="['Staff', 'Agent', 'Home Owner']"
                                     :rules="[rules.required, typeRules]"
                                      @change="selected_user.Role"
                                ></v-select>
                                 </v-col>
                              </v-row>
                            </v-form>
                          </v-container>
                        </v-card-text>
                        </v-card-actions>
                      </v-card>
                      <v-expand-transition>
                      <v-card
                          v-if="selected_user.Role === 'Home Owner'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                          <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Homeowner Info
                               </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryFirstName"
                                      label="Person 1 First Name"
                                      :rules="[rules.required]"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryLastName"
                                    label="Person 1 Last Name"
                                    :rules="[rules.required]"
                                  />
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.SecondaryFirstName"
                                      label="Person 2 First Name"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.SecondaryLastName"
                                    label="Person 2 Last Name"
                                  />
                                </v-col>

                                <v-col
                                cols="12">
                                  <v-text-field
                                      v-model="selected_user.Address"
                                    label="Address & Legal"
                                    :rules="[rules.required]"
                                  />
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="4"
                                >
                                  <v-text-field
                                      v-model="selected_user.City"
                                    label="City"
                                    :rules="[rules.required]"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="4"
                                >
                                  <v-text-field
                                      v-model="selected_user.State"
                                    label="State"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="4"
                                >
                                  <v-text-field
                                      v-model="selected_user.Zip"
                                    label="Zip Code"
                                    type="number"
                                    :rules="[rules.required]"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.BuildStart"
                                    label="Purchase Agreement Date"
                                    type="date"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.BuildEnd"
                                    label="Closing Date"
                                    type="date"
                                  />
                                </v-col>

                               <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.CellNumber"
                                    label="Primary Phone"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.HomeNumber"
                                    label="Secondary Phone"
                                  />
                                </v-col>

                               <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryEmailAddress"
                                    label="Person 1 Email Address"
                                    :rules="[rules.email, emailRules]"
                                  />
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.SecondaryEmailAddress"
                                    label="Person 2 Email Address"
                                    :rules="[rules.email, emailRules]"
                                  />
                                </v-col>
                                <v-col
                                  cols="12"
                                  md="4"
                                >
                                  <v-text-field
                                      v-model="selected_user.Agent"
                                    label="Agent Email"
                                    :rules="[rules.email, emailRules]"
                                  />
                                </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sendAuthEmailBtn"
                            >
                              Send Auth Email
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="save"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                      </v-card>
                        <v-card
                          v-if="selected_user.Role === 'Agent'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                          <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Agent Info
                               </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryFirstName"
                                      label="First Name"
                                      :rules="[rules.required]"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryLastName"
                                    label="Last Name"
                                    :rules="[rules.required]"
                                  />
                                </v-col>

                                <v-col
                                  cols="6"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryNumber"
                                    label="Primary Phone"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.SecondaryNumber"
                                    label="Secondary Phone"
                                  />
                                </v-col>

                               <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryEmailAddress"
                                    label="Email Address"
                                    :rules="[rules.required,rules.email, emailRules]"
                                  />
                                </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sendAuthEmailBtn"
                            >
                              Send Auth Email
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="save"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                      </v-card>
                        <v-card
                          v-if="selected_user.Role === 'Staff'"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                          <v-card-text>
                            <v-container>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                              >
                              <v-row>

                               <!-- Partner 1 -->

                               <v-col
                                cols="12"
                                md="13"
                                class="card-title font-weight-light pt-6">
                                Staff Info
                               </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryFirstName"
                                      label="First Name"
                                      :rules="[rules.required]"
                                  />
                                </v-col>

                                <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryLastName"
                                    label="Last Name"
                                    :rules="[rules.required]"
                                  />
                                </v-col>

                               <v-col
                                  cols="12"
                                  md="6"
                                >
                                  <v-text-field
                                      v-model="selected_user.PrimaryEmailAddress"
                                    label="Email Address"
                                    :rules="[rules.required,rules.email, emailRules]"
                                  />
                                </v-col>
                                </v-row>
                              </v-form>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="sendAuthEmailBtn"
                            >
                              Send Auth Email
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              :disabled="!valid"
                              @click="save"
                            >
                              Save
                            </v-btn>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                      </v-card>
                      </v-expand-transition>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title >Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
<!--                <template v-slot:[`item.ModifiedDate`]="{ item }">-->
<!--                  <span>{{ new Date(item.ModifiedDate).toLocaleString() }}</span>-->
<!--                </template>-->
                <template v-slot:[`item.user`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="view_user(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </base-material-card>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
export default {
    name:'newuser',
    data () {
      return {
        url:process.env.VUE_APP_URL,
        dialog: false,
        dialogDelete: false,
        editedIndex:  -1,
        valid: false,
        rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
        emailRules: [
          v => !!v || 'Email is required',
          v => (v && v.length <= 25) || 'Max 255 characters.',
          ],
        typeRules: [
          v => !!v || 'Type is required',
        ],
        Headers: [
            {
            text: 'User Id',
            sortable: false,
            class:"primary--text",
            value: 'UserId',
            },
            {
            text: 'Primary Email Address',
            sortable: false,
            class:"primary--text",
            value: 'PrimaryEmailAddress',
            },
            {
            text: 'First Name',
            sortable: false,
            class:"primary--text",
            value: 'PrimaryFirstName',
            },
           {
            text: 'Last Name',
            sortable: false,
            class:"primary--text",
            value: 'PrimaryLastName',
            },
            {
            text: 'Secondary Email Address',
            sortable: false,
            class:"primary--text",
            value: 'SecondaryEmailAddress',
            },
            {
            text: 'First Name',
            sortable: false,
            class:"primary--text",
            value: 'SecondaryFirstName',
            },
           {
            text: 'Last Name',
            sortable: false,
            class:"primary--text",
            value: 'SecondaryLastName',
            },
            {
            text: 'Role',
            value: 'Role',
            sortable: true,
            class:"primary--text",
            },
            {
            text: 'Edit',
            value: 'user',
            align: 'end',
            sortable: false,
            class:"primary--text",
            }
        ],
       user: [],
        selected_user: {
            UserId: '',
            HomeId: '',
            PrimaryEmailAddress:'',
            SecondaryEmailAddress:'',
            PrimaryFirstName: '',
            PrimaryLastName: '',
            SecondaryFirstName: '',
            SecondaryLastName: '',
            Address:'',
            Legal_Description:'',
            City:'',
            State: '',
            Zip: '',
            BuildStart: '',
            BuildEnd:'',
            PrimaryNumber:'',
            SecondaryNumber: '',
            CellNumber:'',
            HomeNumber: '',
            Role:'',
            Agent:''
        },
        default_user: {
            UserId: '',
            HomeId: '',
            PrimaryEmailAddress:'',
            SecondaryEmailAddress:'',
            PrimaryFirstName: '',
            PrimaryLastName: '',
            SecondaryFirstName: '',
            SecondaryLastName: '',
            Address:'',
            Legal_Description:'',
            City:'',
            State: '',
            Zip: '',
            BuildStart: '',
            BuildEnd:'',
            PrimaryNumber:'',
            SecondaryNumber: '',
            CellNumber:'',
            HomeNumber: '',
            Role:'',
            Agent:''
        },
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Person' : 'Edit User'
      },
      permissions(){
        return {
          scope: localStorage.getItem("permissions")
        }
      },
      bearerToken(){
        return axios.defaults.headers.common['Authorization']
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.initialize()
    },
    methods: {
        initialize () {
        axios.get(process.env.VUE_APP_GET_USER_URL,
        {
          // params: {
          //   user_id: this.UserId,
          // }
        }
        )
        .then(response => {
          this.user = response.data
        })
        .catch(error => {
          console.log(error)
        })
        },
        view_user (user) {
          this.editedIndex = this.user.indexOf(user)
          this.selected_user = Object.assign({}, user)
          this.dialog = true
        },
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.selected_user= Object.assign({}, this.default_user)
            this.editedIndex = -1
          })
        },
        save () {
          var body = {
              "user_id": this.selected_user.UserId,
              "home_id": this.selected_user.HomeId,
              "primary_last_name": this.selected_user.PrimaryLastName,
              "primary_first_name": this.selected_user.PrimaryFirstName,
              "secondary_last_name": this.selected_user.SecondaryLastName,
              "secondary_first_name": this.selected_user.SecondaryFirstName,
              "address": this.selected_user.Address || '2677 Bunker Lake Blvd. NW., Andover, MN 55304',
              "city": this.selected_user.City || 'Andover',
              "state": this.selected_user.State || 'MN',
              "zip": this.selected_user.Zip || '55304',
              "build_start": this.selected_user.BuildStart,
              "build_end": this.selected_user.BuildEnd,
              "primary_email": this.selected_user.PrimaryEmailAddress || `homeOwner_${Math.random().toString(36).substr(2, 7).toUpperCase()}@bouldercontractingllc.com`,
              "secondary_email": this.selected_user.SecondaryEmailAddress,
              "role": this.selected_user.Role,
              "agent": this.selected_user.Agent || 'None',
              "primary_number": this.selected_user.PrimaryNumber || '763-323-6226',
              "secondary_number": this.selected_user.SecondaryNumber || '763-323-6226',
              "token": this.bearerToken,
            }
          if (this.editedIndex > -1) {
            axios
            .post(process.env.VUE_APP_UPDATE_USER_URL,body)
            .then(() => {
            })
            .catch(error => {
              console.log(error)
            })
            Object.assign(this.user[this.editedIndex], this.selected_user)
          } else {
            axios
            .post(process.env.VUE_APP_INSERT_USER_URL,body)
            .then(response => {
              console.log("INSERT_USER response",response.data)
              this.initialize()
            })
            .catch(error => {
              console.log(error)
              this.initialize()
            })
          }
          this.selected_user = Object.assign({}, this.default_user)
          this.editedIndex = -1
          this.close()
        },
        sendAuthEmailBtn () {
          var body = {
              "user_id": this.selected_user.UserId,
              "home_id": this.selected_user.HomeId,
              "primary_last_name": this.selected_user.PrimaryLastName,
              "primary_first_name": this.selected_user.PrimaryFirstName,
              "secondary_last_name": this.selected_user.SecondaryLastName,
              "secondary_first_name": this.selected_user.SecondaryFirstName,
              "address": this.selected_user.Address || '2677 Bunker Lake Blvd. NW., Andover, MN 55304',
              "city": this.selected_user.City || 'Andover',
              "state": this.selected_user.State || 'MN',
              "zip": this.selected_user.Zip || '55304',
              "build_start": this.selected_user.BuildStart,
              "build_end": this.selected_user.BuildEnd,
              "primary_email": this.selected_user.PrimaryEmailAddress,
              "secondary_email": this.selected_user.SecondaryEmailAddress,
              "role": this.selected_user.Role,
              "agent": this.selected_user.Agent,
              "primary_number": this.selected_user.PrimaryNumber || '763-323-6226',
              "secondary_number": this.selected_user.SecondaryNumber || '',
              "token": this.bearerToken,
            }
          
            axios
            .post(process.env.VUE_APP_UPDATE_USER_URL,body)
            .then(response => {
              console.log(response.data)
              this.initialize()
            })
            .catch(error => {
              console.log(error)
            })
            axios
            .post(process.env.VUE_APP_INSERT_AUTHUSER_URL,body)
            .then(response => {
              console.log(response.data)
            })
            .catch(error => {
              console.log(error)
            })
            Object.assign(this.user[this.editedIndex], this.selected_user)
            this.selected_user = Object.assign({}, this.default_user)
            this.editedIndex = -1
            this.close()
        },
        deleteItem (user) {
          this.editedIndex = this.user.indexOf(user)
          this.selected_user = Object.assign({}, user)
          this.dialogDelete = true
        },
        deleteItemConfirm () {
          var item = this.user[this.editedIndex]
          var body = {"user_id":item.UserId}
          axios
          .post(process.env.VUE_APP_DELETE_USER_URL,body)
          .then(response => {
            console.log(response.data)
            this.initialize()
          })
          .catch(error => {
            console.log(error)
          })
          // this.cost_breakdown.splice(this.editedIndex, 1)
          this.closeDelete()
          },
        closeDelete () {
          this.dialogDelete = false
          this.$nextTick(() => {
            this.selected_user = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
          })
        },
    },
}
</script>
<style lang="scss" scoped>
</style>